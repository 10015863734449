export const formatDateTime = (date) => new Date(date).toLocaleString('default', {
  year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric',
});

export const formatMonth = (date) => new Date(date).toLocaleString('default', { month: 'short', year: 'numeric' });

export const sortByDate = (a, b) => {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
};
