import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@material-ui/core';
import ChangePasswordForm from '../blocks/Login/ChangePasswordForm';
import Page from './Page';

const ChangePasswordPage = ({ history }) => (
  <Page>
    <Container maxWidth="sm">
      <ChangePasswordForm
        onSuccess={() => history.push('/')}
      />
    </Container>
  </Page>
);

ChangePasswordPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default ChangePasswordPage;
