import React from 'react';
import { Backdrop, CircularProgress } from '@material-ui/core';

import useAuth from './hooks/useAuth';
import { renderRoutes } from './utils/renderRoutes';
import useAbilityList from './hooks/useAbilityList';
import routes from './routes';

const App = () => {
  const { isFinished, isAuthorized } = useAuth();
  const awailableRoutes = useAbilityList(routes, 'routes');

  if (!isAuthorized && !isFinished) {
    return (
      <Backdrop invisible open>
        <CircularProgress />
      </Backdrop>
    );
  }

  return renderRoutes(awailableRoutes);
};

export default App;
