import { object, number, string } from 'yup';
import { ERROR_MESSAGES } from '../../../constants/validation.constants';

export const SOURCE_INITIAL_VALUE = {
  source: {
    id: '',
  },
  value: '',
};

export const SOURCE_VALIDATION_SCHEMA = object({
  source: object({
    id: string().required(ERROR_MESSAGES.required),
  })
    .nullable(),
  value: number(ERROR_MESSAGES.invalid)
    .positive(ERROR_MESSAGES.positive)
    .required(ERROR_MESSAGES.required),
});
