import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRequest, useMutation } from 'redux-query-react';
import useAbilityList from '../../hooks/useAbilityList';

const defaultGetFormInitialValues = (editItem, initialValues) => editItem || initialValues;
const defaultRequest = () => null;

const useCrudDataTable = ({
  title,
  apiModule = {},
  FormComponent,
  initialValues,
  dialogProps,
  getFormInitialValues = defaultGetFormInitialValues,
  columns: allColumns,
  ...other
}) => {
  const {
    createMutation = defaultRequest,
    getListRequest = defaultRequest,
    updateMutation = defaultRequest,
    deleteMutation = defaultRequest,
    listSelector = () => [],
  } = apiModule;

  const columns = useAbilityList(allColumns);

  const [{ isPending }] = useRequest(getListRequest());

  const [, createItem] = useMutation(createMutation);
  const [, editRow] = useMutation(updateMutation);
  const [, deleteRow] = useMutation(deleteMutation);
  const data = useSelector(listSelector);

  const [isOpenEditDialog, setIsOpenEditDialog] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [dialogTitle, setDialogTitle] = useState(null);
  const handleCloseEditDialog = useCallback(() => setIsOpenEditDialog(false), []);

  const handleCreate = useCallback(() => {
    setEditItem(null);
    setIsOpenEditDialog(true);
    setDialogTitle(`Create new ${title}`);
  }, [title]);

  const handleEditRow = useCallback((item) => {
    setEditItem(item);
    setIsOpenEditDialog(true);
    setDialogTitle(`Edit ${title}`);
  }, [title]);

  const getDialogProps = useCallback(() => ({
    ...dialogProps,
    open: isOpenEditDialog,
    onClose: handleCloseEditDialog,
  }), [dialogProps, handleCloseEditDialog, isOpenEditDialog]);

  const getFormProps = useCallback(() => ({
    onReset: handleCloseEditDialog,
    onSuccess: handleCloseEditDialog,
    onSubmit: editItem ? editRow : createItem,
    initialValues: getFormInitialValues(editItem, initialValues),
    enableReinitialize: true,
  }), [createItem, editItem, editRow, getFormInitialValues, handleCloseEditDialog, initialValues]);

  return {
    isPending,
    data,
    canCreate: !!(apiModule.createMutation && FormComponent),
    canEdit: !!(apiModule.updateMutation && FormComponent),
    canDelete: !!(apiModule.deleteMutation),
    onCreate: handleCreate,
    onEditRow: handleEditRow,
    onDeleteRow: deleteRow,
    title,
    dialogTitle,
    columns,

    getDialogProps,
    getFormProps,
    FormComponent,
    ...other,
  };
};

export default useCrudDataTable;
