import { createMuiTheme } from '@material-ui/core';

import palette from './palette';

const theme = createMuiTheme({
  palette,
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
  overrides: {
    MuiDialogContent: {
      root: {
        padding: '16px',
      },
    },
    MuiTableCell: {
      stickyHeader: {
        backgroundColor: 'white',
      },
    },
    MuiGrid: {
      container: {
        '@media print': {
          display: 'block !important',
        },
      },
    },
  },
});

export default theme;
