import React from 'react';

import { useMutation } from 'redux-query-react';
import { changePasswordMutation } from '../../modules/user.module';
import PasswordForm from './PasswordForm';

const ChangePasswordForm = (props) => {
  const [, resetPassword] = useMutation(changePasswordMutation);

  return (
    <PasswordForm
      onSubmit={resetPassword}
      title="Change Password"
      oldPassword
      {...props}
    />
  );
};

export default ChangePasswordForm;
