/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link, Typography } from '@material-ui/core';
import { useMutation } from 'redux-query-react';
import { useField } from 'formik';
import { restorePasswordMutation } from '../../modules/user.module';
import ConfirmDialog from '../../components/ConfirmDialog';

const ForgotPasswordButton = () => {
  const [{ value: email }, { error }] = useField('email');
  const [, forgotPassword] = useMutation(restorePasswordMutation);

  const handleClick = () => forgotPassword(email)
    .then((res) => {
      if (res.status !== 200) {
        throw new Error();
      }
    });

  if (error || !email) {
    return null;
  }

  return (
    <ConfirmDialog
      title="Reset Password"
      message={(
        <Typography variant="body1">{`After clicking Reset Password below, an email will be sent to "${email}" with a link to reset password.`}</Typography>
    )}
      onOk={handleClick}
      okButtonText="Reset Password"
    >
      <Link
        component="button"
        type="button"
        variant="body2"
        disabled={!!error}
        onClick={handleClick}
      >
        Forgot password?
      </Link>
    </ConfirmDialog>
  );
};

export default ForgotPasswordButton;
