import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, Button, DialogActions, DialogContent, DialogTitle, Typography,
} from '@material-ui/core';

const ConfirmDialog = ({
  onOk, children, title, message, okButtonText, cancelButtonText, ...props
}) => {
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleClick = useCallback(() => {
    setOpen(true);
  }, []);

  const handleOk = useCallback(() => {
    Promise.resolve().then(onOk).then(() => {
      setOpen(false);
    })
      .catch((err) => {
        setErrorMessage(err.message || 'Something went wrong');
      });
  }, [onOk]);

  return (
    <>
      {React.cloneElement(children, { onClick: handleClick })}
      <Dialog {...props} open={open} onClose={handleClose}>
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent>
          {message}
        </DialogContent>
        <DialogActions>
          {errorMessage && (
            <Typography color="error">{errorMessage}</Typography>
          )}
          <Button onClick={handleClose}>{cancelButtonText}</Button>
          <Button variant="contained" color="secondary" onClick={handleOk}>{okButtonText}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

ConfirmDialog.propTypes = {
  onOk: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  message: PropTypes.node.isRequired,
  okButtonText: PropTypes.node,
  cancelButtonText: PropTypes.node,
  title: PropTypes.node,
};

ConfirmDialog.defaultProps = {
  okButtonText: 'Yes',
  cancelButtonText: 'Cancel',
  title: null,
};

export default ConfirmDialog;
