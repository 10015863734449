import React from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Link from '@material-ui/core/Link';
import { Container, Box } from '@material-ui/core';
import useAppSettings from '../hooks/useAppSettings';

const documents = [
  {
    title: 'Acceptable Use Policy',
    link: '/assets/files/Acceptable Use Policy.pdf',
  },
  {
    title: 'Cookie Policy',
    link: '/assets/files/Cookie Policy.pdf',
  },
  {
    title: 'Data Processing Agreement',
    link: '/assets/files/Data Processing Agreement.pdf',
  },
  {
    title: 'General Privacy Policy',
    link: '/assets/files/General Privacy Policy.pdf',
  },
  {
    title: 'Product Privacy Policy',
    link: '/assets/files/Product Privacy Policy.pdf',
  },
  {
    title: 'Terms of Service',
    link: '/assets/files/Terms of Service.pdf',
  },
  {
    title: 'Website Terms of Use',
    link: '/assets/files/Website Terms of Use.pdf',
  },
];

const HomePage = () => {
  const settings = useAppSettings();

  return (
    <div
      style={{
        backgroundImage: 'url(/assets/files/images/background.jpg)',
        height: 'calc(100vh - 64px)',
        backgroundSize: 'cover',
      }}
    >
      <Container component="main" maxWidth="sm">
        <Box pt={8}>
          <Typography variant="h2">Venture Dashboard</Typography>
          <ExpansionPanel defaultExpanded>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>About</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                The Venture Dashboard service allows ventures to collect data about their progress
                on a monthly basis in a secure environment.
                It also allow coaches and advisors to be fully
                informed and enables them to provide contextual advice
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Terms of Service</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <List>
                {documents.map((d, index) => (
                  <ListItem key={index}>
                    <Link href={d.link} target="_blank">
                      {d.title}
                    </Link>
                  </ListItem>
                ))}
              </List>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          {settings && (
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Feedback</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Link href={settings.feedbackLink} target="_blank">
                Go to feedback form
              </Link>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          )}
        </Box>
      </Container>
    </div>
  );
};

export default HomePage;
