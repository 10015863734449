import React from 'react';
import VentureTableListContainer from '../blocks/VentureTableList';
import Page from './Page';

const VenturesPage = () => (
  <Page>
    <VentureTableListContainer />
  </Page>
);

export default VenturesPage;
