import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useRequest } from 'redux-query-react';

import { MenuItem, TextField } from '@material-ui/core';
import { API_SOURCE_CONFIG, DATA_SOURCE } from './DataSelect.config';

const DataSelect = ({ source, ...props }) => {
  const { getListRequest, listSelector } = API_SOURCE_CONFIG[source];

  useRequest(getListRequest());

  const options = useSelector(listSelector);

  return (
    <TextField
      select
      variant="outlined"
      {...props}
    >
      {options.map((option) => (
        <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
      ))}
    </TextField>
  );
};

DataSelect.propTypes = {
  source: PropTypes.oneOf(Object.values(DATA_SOURCE)).isRequired,
};

export default DataSelect;
