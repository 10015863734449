import { createSelector } from 'reselect';
import { entitiesSelector } from './configureStore';

export const loginMutation = (body) => ({
  url: '/api/login',
  body,
  update: {
    user: (prev, next) => next,
  },
  transform: (user) => ({
    user,
  }),
});

export const logoutMutation = () => ({
  url: '/api/logout',
  options: {
    method: 'GET',
  },
  update: {
    user: (prev, next) => next,
  },
  transform: () => ({
    user: null,
  }),
});

export const restorePasswordMutation = (email) => ({
  url: '/api/login/restore-password',
  body: {
    email,
  },
});

export const resetPasswordMutation = ({ password, hash }) => ({
  url: '/api/login/reset-password',
  body: {
    hash,
    password,
  },
});

export const changePasswordMutation = ({ password, oldPassword }) => ({
  url: '/api/login/change-password',
  body: {
    oldPassword,
    password,
  },
});

export const getUserRequest = () => ({
  url: '/api/user',
  update: {
    user: (prev, next) => next,
  },
  transform: (user) => ({
    user,
  }),
});
export const userSelector = createSelector(
  entitiesSelector,
  ({ user }) => user || null,
);
