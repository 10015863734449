import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Button,
  Menu,
  MenuItem,
  Hidden,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { makeStyles } from '@material-ui/styles';

import { useSelector } from 'react-redux';
import { useRequest, useMutation } from 'redux-query-react';
import Sidebar from '../Sidebar';
import { userSelector, getUserRequest, logoutMutation } from '../../modules/user.module';
import { renderRoutes } from '../../utils/renderRoutes';
import Link from '../../components/Link';
import Search from '../../blocks/Search/Search';
import useAbility from '../../hooks/useAbility';
import ROLE from '../../constants/role.constants';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  header: {
    '@media print': {
      display: 'none',
    },
  },
  toolbar: {
    ...theme.mixins.toolbar,
    '@media print': {
      display: 'none',
    },
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    // padding: theme.spacing(3),
  },
}));


const UserInfo = () => {
  useRequest(getUserRequest());
  const [, logout] = useMutation(logoutMutation);
  const user = useSelector(userSelector);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  if (!user) {
    return null;
  }

  return (
    <>
      <Button
        onClick={handleClick}
        color="inherit"
        startIcon={(
          <AccountCircle />
      )}
      >
        {user.email}
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        getContentAnchorEl={null}
      >
        <MenuItem onClick={handleMenuClose} component={Link} to="/change-password">Change Password</MenuItem>
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
    </>
  );
};

const MainLayout = ({ route }) => {
  const { can } = useAbility();
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleDrawerToggle = useCallback(() => {
    setOpen(!open);
  }, [open]);

  return (
    <div className={clsx({
      [classes.root]: true,
    })}
    >
      <AppBar position="fixed" className={classes.header}>
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <Hidden mdUp implementation="css">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Typography variant="h6">
            <Link to="/landing">Venture Dashboard</Link>
          </Typography>
          {can([[ROLE.ADMIN, ROLE.VIEWER]]) && <Search />}
          <UserInfo />
        </Toolbar>
      </AppBar>
      <Sidebar
        open={open}
        onClose={handleDrawerToggle}
        menuItems={route.routes}
      />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {renderRoutes(route.routes)}
      </main>
    </div>
  );
};

MainLayout.propTypes = {
  route: PropTypes.shape({
    routes: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default MainLayout;
