import React, { Suspense } from 'react';
import {
  Switch, Route,
} from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';

export const getFirstAvailableRoute = (routeList, excludeRoute) => {
  if (!routeList) {
    return null;
  }

  if (!Array.isArray(routeList)) {
    return getFirstAvailableRoute([routeList], excludeRoute);
  }

  return routeList.map((route) => {
    if (route.path && (!(excludeRoute && excludeRoute.path) || route.path !== excludeRoute.path)) {
      return route;
    }

    return getFirstAvailableRoute(route.routes, excludeRoute);
  }).filter(Boolean)[0];
};

export const renderRoutes = (routes, extraProps = {}, switchProps = {}) => (routes ? (
  <Suspense fallback={<CircularProgress />}>
    <Switch {...switchProps}>
      {routes.map((route, i) => (
        <Route
          key={route.key || i}
          path={route.path}
          exact={route.exact}
          strict={route.strict}
          render={(props) => (route.render ? (
            route.render({ ...props, ...extraProps, route })
          ) : (
            <route.component {...props} {...extraProps} route={route} routes={routes} />
          ))}
        />
      ))}
    </Switch>
  </Suspense>
) : null);
