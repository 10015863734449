import React from 'react';
import PropTypes from 'prop-types';
import { Container, Box } from '@material-ui/core';
import LoginFormContainer from '../blocks/Login/LoginForm';

const LoginPage = ({ history }) => (
  <Container component="main" maxWidth="xs">
    <Box mt={8}>
      <LoginFormContainer onSuccess={() => history.push('/')} />
    </Box>
  </Container>
);

LoginPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default LoginPage;
