import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import FormField from '../../components/FormField';

const RunwayInput = ({
  burnRateName, cashName, name, ...props
}) => {
  const [, , { setValue }] = useField(name);
  const [{ value: burnRate }] = useField(burnRateName);
  const [{ value: cash }] = useField(cashName);

  useEffect(() => {
    if (burnRate && cash) {
      setValue((cash / burnRate).toFixed());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [burnRate, cash]);

  return (
    <FormField
      {...props}
      name={name}
      variant="outlined"
      type="number"
    />
  );
};

RunwayInput.propTypes = {
  burnRateName: PropTypes.string.isRequired,
  cashName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default RunwayInput;
