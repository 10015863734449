import { useMemo } from 'react';
import useAbility from './useAbility';

const createAblityListChecker = ({ can }, subItemsKey) => function filterItems(items) {
  return items.filter((item) => can(item.permission)).map((item) => {
    if (!item[subItemsKey]) {
      return item;
    }
    return {
      ...item,
      [subItemsKey]: filterItems(item[subItemsKey]),
    };
  });
};

const useAbilityList = (list, subItemsKey = 'items') => {
  const ability = useAbility();

  return useMemo(() => {
    const checkList = createAblityListChecker(ability, subItemsKey);

    return checkList(list);
  }, [ability, list, subItemsKey]);
};

export default useAbilityList;
