import React from 'react';
import PropTypes from 'prop-types';
import { object, string } from 'yup';
import {
  Grid,
} from '@material-ui/core';

import Form from '../../components/Form';
import FormField from '../../components/FormField';
import DataSelect from '../../components/DataSelect';
import { DATA_SOURCE } from '../../components/DataSelect/DataSelect.config';
import { ERROR_MESSAGES } from '../../constants/validation.constants';

export const VALIDATION_SCHEMA = object({
  name: string()
    .max(100, ERROR_MESSAGES.maxNumber)
    .required(ERROR_MESSAGES.required),

  owner: object({
    email: string()
      .email(ERROR_MESSAGES.email)
      .required(ERROR_MESSAGES.required),
  }).nullable(),

  cohort: object().nullable(),
});

export const INITIAL_VALUES = {
  name: '',
  owner: {
    email: '',
  },
  cohort: {
    id: '',
  },
};

const VentureForm = ({ initialValues, ...props }) => (
  <Form
    initialValues={{
      ...INITIAL_VALUES,
      ...initialValues,
    }}
    validationSchema={VALIDATION_SCHEMA}
    {...props}
  >
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <FormField
          name="name"
          id="venture-name"
          label="Name"
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <FormField
          name="owner.email"
          type="email"
          id="venture-owner"
          label="Owner Email"
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <FormField
          as={DataSelect}
          source={DATA_SOURCE.COHORT}
          name="cohort.id"
          label="Cohort"
          variant="outlined"
          fullWidth
        />
      </Grid>
    </Grid>
  </Form>
);

VentureForm.propTypes = {
  initialValues: PropTypes.shape({}),
};

VentureForm.defaultProps = {
  initialValues: null,
};

export default VentureForm;
