import React, { useMemo, useCallback } from 'react';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import {
  Chip, InputBase, fade,
} from '@material-ui/core';
import { useRequest } from 'redux-query-react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import qs from 'qs';
import { makeStyles } from '@material-ui/styles';
import SearchIcon from '@material-ui/icons/Search';
import { venturesApiModule } from '../../modules/ventures.module';
import Link from '../../components/Link';

const useStyles = makeStyles((theme) => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200,
      },
    },
  },
}));

const SearchInput = ({ InputProps, inputProps }) => {
  const classes = useStyles();

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        ref={InputProps.ref}
        inputProps={inputProps}
        placeholder="Search…"
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
      />
    </div>
  );
};

const Search = () => {
  const { push } = useHistory();
  useRequest(venturesApiModule.getListRequest());
  const ventures = useSelector(venturesApiModule.listSelector);

  const cohorts = useSelector(venturesApiModule.cohortsSelector);

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option) => option.name,
  });

  const options = useMemo(() => [...cohorts.map((c) => ({
    ...c,
    type: 'cohort',
  })), ...ventures.map((v) => ({
    ...v,
    type: 'venture',
  }))], [cohorts, ventures]);

  const handleChange = useCallback((e, option) => {
    if (!option) {
      return;
    }

    if (option.type === 'venture') {
      push(`/ventures/${option.id}`);
    } else if (option.type === 'cohort') {
      push({
        pathname: '/dashboard',
        search: qs.stringify({
          cohorts: [option.id],
        }),
      });
    }
  }, [push]);

  return (
    <Autocomplete
      style={{ width: 300 }}
      options={options}
      filterOptions={filterOptions}
      blurOnSelect
      getOptionLabel={() => ''}
      onChange={handleChange}
      renderOption={(option) => (
        <Link to={`/ventures/${option.id}`}>
          {option.name}
          {option.cohort && (
            <Chip
              size="small"
              label={option.cohort.name}
            />
          )}
        </Link>
      )}
      renderInput={SearchInput}
    />
  );
};

export default Search;
