import {
  string, object,
} from 'yup';
import { ERROR_MESSAGES } from '../../../constants/validation.constants';

export const GOAL_STATUS = {
  COMPLETED: 'COMPLETED',
  IN_PROGRESS: 'IN_PROGRESS',
  INCOMPLETE: 'INCOMPLETE',
};

export const GOAL_INITIAL_VALUE = {
  status: GOAL_STATUS.IN_PROGRESS,
  text: '',
};

export const GOAL_VALIDATION_SCHEMA = object({
  status: string().nullable().required(ERROR_MESSAGES.required),
  text: string(ERROR_MESSAGES.invalid)
    .required(ERROR_MESSAGES.required),
});
