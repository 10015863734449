import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid, InputAdornment,
} from '@material-ui/core';
import FormField from '../../../components/FormField';
import DataSelect from '../../../components/DataSelect';

const SourceFieldSet = ({ name, label, sourceType }) => (
  <Grid container wrap="nowrap">
    <Grid item xs>
      <FormField
        as={DataSelect}
        source={sourceType}
        name={`${name}.source.id`}
        label={label}
        fullWidth
      />
    </Grid>
    <Grid item xs={4}>
      <FormField
        name={`${name}.value`}
        label="Amount"
        InputProps={{
          startAdornment: <InputAdornment position="start">£</InputAdornment>,
        }}
        variant="outlined"
        width={100}
      />
    </Grid>
  </Grid>
);

SourceFieldSet.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  sourceType: PropTypes.string.isRequired,
};

export default SourceFieldSet;
