import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import FormFieldList from '../../../components/FormFieldList';
import SourceFieldSet from './SourceFieldSet';
import { SOURCE_INITIAL_VALUE } from './SourceFieldArray.config';
import { DATA_SOURCE } from '../../../components/DataSelect/DataSelect.config';

const TITLE_BY_SOURCE_TYPE = {
  [DATA_SOURCE.DEBTS]: {
    title: 'Debt',
    description: 'New debt incurred this month',
  },
  [DATA_SOURCE.INVESTMENTS]: {
    title: 'Investments',
    description: 'New investment acquired this month',
  },
  [DATA_SOURCE.GRANTS]: {
    title: 'Grants',
    description: 'New grants acquired this month',
  },
  [DATA_SOURCE.VALUATIONS]: {
    title: 'Valuations',
    description: 'New valuation estimate this month',
  },
};

const SourceFieldArray = ({
  name, sourceType,
}) => {
  const [{ value }, , { setValue }] = useField(name);

  const handleAdd = useCallback(() => {
    setValue([...value, {
      key: Date.now(),
      ...SOURCE_INITIAL_VALUE,
    }]);
  }, [setValue, value]);

  const handleRemove = useCallback((e, index) => {
    setValue(value.filter((item, i) => i !== index));
  }, [setValue, value]);

  const renderItem = useCallback((item, index) => (
    <SourceFieldSet
      name={`${name}[${index}]`}
      label={`Source ${index + 1}`}
      sourceType={sourceType}
    />
  ), [name, sourceType]);

  return (
    <FormFieldList
      {...TITLE_BY_SOURCE_TYPE[sourceType]}
      onAdd={handleAdd}
      onRemove={handleRemove}
      data={value}
      renderItem={renderItem}
    />
  );
};

SourceFieldArray.propTypes = {
  name: PropTypes.string.isRequired,
  sourceType: PropTypes.string.isRequired,
};

export default memo(SourceFieldArray);
