import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  TableContainer,
  Toolbar,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import ConfirmDialog from '../ConfirmDialog';
import DataTable from '../DataTable';
import ROLE from '../../constants/role.constants';

const EditCellButton = ({ onEditRow, row }) => (
  <IconButton size="small" onClick={() => onEditRow(row.original)}>
    <EditIcon />
  </IconButton>
);
EditCellButton.propTypes = {
  onEditRow: PropTypes.func.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({}).isRequired,
  }).isRequired,
};

const DeleteCellButton = ({ onDeleteRow, row }) => (
  <ConfirmDialog
    onOk={() => onDeleteRow(row.original.id)}
    message={<Typography variant="h6">Are you sure you want to delete this item?</Typography>}
  >
    <IconButton size="small">
      <DeleteIcon />
    </IconButton>
  </ConfirmDialog>
);
DeleteCellButton.propTypes = {
  onDeleteRow: PropTypes.func.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export const EDIT_CELL_COLUMN = {
  Header: '',
  id: 'edit',
  disableSortBy: true,
  Cell: EditCellButton,
  props: { padding: 'checkbox' },
  permission: [ROLE.ADMIN, ROLE.USER],
};

export const DELETE_CELL_COLUMN = {
  Header: '',
  id: 'delete',
  disableSortBy: true,
  Cell: DeleteCellButton,
  props: { padding: 'checkbox' },
  permission: [ROLE.ADMIN],
};

const CrudDataTable = ({
  title,
  FormComponent,
  dialogTitle,
  getDialogProps,
  getFormProps,
  canCreate,
  onCreate,
  isPending,
  ...props
}) => {
  const dialogProps = getDialogProps && getDialogProps();

  return (
    <Paper elevation={0}>
      <LinearProgress color="primary" style={{ visibility: (isPending ? 'visible' : 'hidden') }} />
      <TableContainer>
        {canCreate && (
        <Toolbar>
          <Button
            onClick={onCreate}
            color="primary"
            variant="contained"
            startIcon={<AddCircleOutlineIcon />}
          >
            {`Create New ${title}`}
          </Button>
        </Toolbar>
        )}
        <DataTable {...props} />
        {FormComponent && (
          <Dialog {...dialogProps}>
            <DialogTitle>
              {dialogTitle}
              {dialogProps.onClose ? (
                <IconButton
                  aria-label="close"
                  onClick={dialogProps.onClose}
                  size="small"
                  style={{ position: 'absolute', top: 16, right: 16 }}
                >
                  <CloseIcon />
                </IconButton>
              ) : null}
            </DialogTitle>
            <DialogContent>
              <FormComponent
                {...getFormProps()}
              />
            </DialogContent>
          </Dialog>
        )}
      </TableContainer>
    </Paper>
  );
};

export default CrudDataTable;
