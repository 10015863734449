import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { userSelector } from '../modules/user.module';

const useAbility = () => {
  const user = useSelector(userSelector);

  return useMemo(() => ({
    user,
    can: (permission) => {
      if (!permission) {
        return true;
      }
      if (!user) {
        return false;
      }
      if (Array.isArray(permission)) {
        return permission.includes(user.role);
      }

      return user.isAdmin || !permission.adminOnly;
    },
  }), [user]);
};

export default useAbility;
