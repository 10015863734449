import React from 'react';
import {
  Grid, Card, CardContent, Typography,
} from '@material-ui/core';
import { number, object } from 'yup';
import FormField from '../../components/FormField';
import { ERROR_MESSAGES } from '../../constants/validation.constants';

export const HEADCOUNT_INITIAL_VALUE = {
  fullTime: 0,
  partTime: 0,
  contractors: 0,
  others: 0,
};

export const VALIDATION_SCHEMA = object({
  fullTime: number(ERROR_MESSAGES.invalid)
    .min(0, ERROR_MESSAGES.minNumber)
    .required(ERROR_MESSAGES.required),
  partTime: number(ERROR_MESSAGES.invalid)
    .min(0)
    .required(ERROR_MESSAGES.required),
  contractors: number(ERROR_MESSAGES.invalid)
    .min(0, ERROR_MESSAGES.minNumber)
    .required(ERROR_MESSAGES.required),
  others: number(ERROR_MESSAGES.invalid)
    .min(0, ERROR_MESSAGES.minNumber)
    .required(ERROR_MESSAGES.required),
});

const HeadcountFieldSet = () => (
  <Card variant="outlined">
    <CardContent>
      <Typography variant="h6">
        Headcount
      </Typography>
      <Typography variant="body2" color="textSecondary" component="p">
        All individuals working in the venture this month
      </Typography>
    </CardContent>
    <CardContent>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormField
            name="headcount.fullTime"
            id="full-time"
            label="Full Time"
            type="number"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormField
            name="headcount.partTime"
            id="part-time"
            label="Part Time"
            type="number"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormField
            name="headcount.contractors"
            id="contractors"
            label="Contractors"
            type="number"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormField
            name="headcount.others"
            id="others"
            label="Others"
            type="number"
            variant="outlined"
          />
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

export default HeadcountFieldSet;
