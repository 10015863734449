import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form as FormikForm, ErrorMessage } from 'formik';
import { Grid, Button, FormHelperText } from '@material-ui/core';

const Form = ({
  children, onSubmit, showButtons, onSuccess, onCancel, initialValues, ...props
}) => {
  const handleSubmit = useCallback(({ serverError, ...data }, { setFieldError }) => onSubmit(data)
    .then((res) => {
      if (res.status < 300) {
        return onSuccess(res);
      }
      if (res.body.message) {
        setFieldError('serverError', res.body.message);
      }
      return res;
    }), [onSubmit, onSuccess]);
  return (
    <Formik
      {...props}
      initialValues={useMemo(() => ({ ...initialValues, serverError: '' }), [initialValues])}
      onSubmit={handleSubmit}
    >
      {({ dirty, isSubmitting }) => (
        <FormikForm noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {children}
            </Grid>

            {showButtons && (
            <Grid container item spacing={2} justify="flex-end">
              <Grid item>
                <ErrorMessage
                  error
                  name="serverError"
                  component={FormHelperText}
                />
              </Grid>
              {onCancel && (
              <Grid item>
                <Button
                  type="reset"
                >
                  Cancel
                </Button>
              </Grid>
              )}
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!dirty || isSubmitting}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
            )}
          </Grid>
        </FormikForm>
      )}
    </Formik>
  );
};

Form.propTypes = {
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  onCancel: PropTypes.func,
  showButtons: PropTypes.bool,
  initialValues: PropTypes.shape({}),
};

Form.defaultProps = {
  onCancel: null,
  onSuccess: (res) => res,
  showButtons: true,
  initialValues: null,
};

export default Form;
