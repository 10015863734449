import { createSelector } from 'reselect';
import { entitiesSelector } from './configureStore';

const crudModuleBuilder = ({ subject, force }) => ({
  createMutation: (body) => ({
    url: `/api/${subject}`,
    body,
    options: {
      method: 'POST',
    },
    update: {
      [subject]: (prev, next) => [next, ...prev],
    },
    transform: (data) => ({
      [subject]: data,
    }),
  }),
  getListRequest: () => ({
    url: `/api/${subject}`,
    force,
    update: {
      [subject]: (prev, next) => next,
    },
    transform: (data) => ({
      [subject]: data,
    }),
  }),
  updateMutation: (body) => ({
    url: `/api/${subject}/${body.id}`,
    body,
    options: {
      method: 'PUT',
    },
    update: {
      [subject]: (prev, next) => prev.map((item) => (item.id === next.id ? next : item)),
    },
    transform: (data) => ({
      [subject]: data,
    }),
  }),
  deleteMutation: (id) => ({
    url: `/api/${subject}/${id}`,
    options: {
      method: 'DELETE',
    },
    update: {
      [subject]: (prev) => prev.filter((item) => (item.id !== id)),
    },
    transform: (data) => ({
      [subject]: data,
    }),
  }),
  listSelector: createSelector(
    entitiesSelector,
    ({ [subject]: list }) => list || [],
  ),
});

export default crudModuleBuilder;
