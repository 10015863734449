import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { TextField } from '@material-ui/core';

const FormField = ({
  disabled,
  as: Component,
  helperText,
  name,
  ...props
}) => (
  <Field
    name={name}
  >
    {({
      field,
      form: { isSubmitting },
      meta,
    }) => {
      const showError = meta.error && meta.touched;

      const newProps = {
        disabled: disabled || isSubmitting,
        error: showError,
        helperText: showError ? (
          <>
            {meta.error}
            {' '}
            {helperText}
          </>
        ) : helperText,
      };

      return (
        <Component
          {...field}
          {...props}
          {...newProps}
        />
      );
    }}
  </Field>
);

FormField.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  as: PropTypes.elementType,
  helperText: PropTypes.node,
};

FormField.defaultProps = {
  as: TextField,
  disabled: false,
  helperText: null,
};

export default FormField;
