import React from 'react';
import ListCrudTable from '../blocks/DataManagement/ListCrudTable.container';
import Page from './Page';

const UserManagementPage = () => (
  <Page>
    <ListCrudTable
      type="users"
    />
  </Page>
);

export default UserManagementPage;
