import { createStore, applyMiddleware, combineReducers } from 'redux';
import {
  entitiesReducer, queryMiddleware, errorsReducer, queriesReducer,
} from 'redux-query';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import superagentInterface from 'redux-query-interface-superagent';

export const queriesSelector = (state = {}) => state.queries || {};
export const entitiesSelector = (state = {}) => state.entities || {};
export const errorsSelector = (state = {}) => state.errors || {};

const rootReducer = combineReducers({
  entities: entitiesReducer,
  queries: queriesReducer,
  errors: errorsReducer,
});

export default function configureStore() {
  return createStore(
    rootReducer,
    composeWithDevTools(
      applyMiddleware(
        queryMiddleware(
          superagentInterface,
          queriesSelector,
          entitiesSelector,
          errorsSelector,
        ),
      ),
    ),
  );
}
