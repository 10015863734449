import React from 'react';
import {
  Grid, Button, Typography, FormHelperText,
} from '@material-ui/core';
import { string, object } from 'yup';
import { useMutation } from 'redux-query-react';

import { ErrorMessage } from 'formik';

import { loginMutation } from '../../modules/user.module';
import Form from '../../components/Form';
import FromField from '../../components/FormField';
import { ERROR_MESSAGES, VALIDATION_RULES } from '../../constants/validation.constants';
import ForgotPasswordButton from './ForgotPasswordButton';

const INITIAL_VALUES = {
  email: '',
  password: '',
};

const VALIDATION_SCHEMA = object({
  email: string().email(ERROR_MESSAGES.email).required(ERROR_MESSAGES.required),
  password: VALIDATION_RULES.password.required(ERROR_MESSAGES.required),
});

const LoginForm = (props) => {
  const [, login] = useMutation(loginMutation);

  return (
    <Form
      initialValues={INITIAL_VALUES}
      validationSchema={VALIDATION_SCHEMA}
      showButtons={false}
      onSubmit={login}
      {...props}
    >
      <Typography variant="h4" align="center">
        Sign in
      </Typography>

      <FromField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        type="email"
        id="email"
        label="Email"
        name="email"
        autoComplete="email"
      />
      <FromField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="current-password"
      />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ErrorMessage
            error
            name="serverError"
            component={FormHelperText}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            Sign In
          </Button>
        </Grid>
        <Grid item xs>
          <ForgotPasswordButton />
        </Grid>
      </Grid>
    </Form>
  );
};

export default LoginForm;
