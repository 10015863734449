import { useSelector } from 'react-redux';
import { useRequest } from 'redux-query-react';
import { getUserRequest, userSelector } from '../modules/user.module';

export default () => {
  const [{ isPending, isFinished }] = useRequest(getUserRequest());
  const user = useSelector(userSelector);

  return {
    isPending,
    isFinished,
    isAuthorized: !!user,
    user,
  };
};
