import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { INITIAL_VALUES } from '../VenturePeriodForm/VenturePeriodForm.config';
import VenturePeriodForm from '../VenturePeriodForm/VenturePeriodForm';
import CrudDataTable, { DELETE_CELL_COLUMN, EDIT_CELL_COLUMN } from '../../components/CrudDataTable/CrudDataTable';
import useCrudDataTable from '../../components/CrudDataTable/useCrudDataTable';
import { venturePeriodApiModule } from '../../modules/ventures.module';
import { formatMonth, formatDateTime, sortByDate } from '../../helpers/dateUtils';
import { toCurrency } from '../../helpers/currency';
import useAbility from '../../hooks/useAbility';
import ROLE from '../../constants/role.constants';

const PERIOD_COLUMNS = [
  {
    Header: 'Period',
    id: 'period',
    accessor: ({ date }) => formatMonth(date),
    sortType: ({ original: { date: a } }, { original: { date: b } }) => sortByDate(a, b),
  },
  {
    Header: 'Stage',
    id: 'stage',
    accessor: (row) => row.stage.name,
  },
  {
    Header: 'Cash in the bank',
    id: 'cashInBank',
    accessor: (row) => toCurrency(row.cashInBank),
  },
  {
    Header: 'Burn Rate',
    id: 'burnRate',
    accessor: (row) => toCurrency(row.burnRate),
  },
  {
    Header: 'Sales',
    id: 'sales',
    accessor: (row) => toCurrency(row.sales),
  },
  {
    Header: 'Last Updated At',
    id: 'updatedAt',
    accessor: (row) => formatDateTime(row.updatedAt),
  },
  EDIT_CELL_COLUMN,
  DELETE_CELL_COLUMN,
];

const VenturePeriodsTable = ({ ventureId, data }) => {
  const { can } = useAbility();

  const options = {
    title: 'Period',
    FormComponent: VenturePeriodForm,
    initialValues: INITIAL_VALUES,
    columns: PERIOD_COLUMNS,
    canCreate: can([ROLE.ADMIN, ROLE.USER]),
    getFormInitialValues: (editItem, initialValues) => {
      if (editItem) {
        return editItem;
      }

      if (!data.length) {
        return initialValues;
      }

      const lastPeriodDate = new Date(data[data.length - 1].date);

      return {
        ...initialValues,
        date: new Date(lastPeriodDate.setMonth(lastPeriodDate.getMonth() + 1)),
      };
    },
    apiModule: {
      createMutation: (period) => venturePeriodApiModule.createMutation(ventureId, period),
      updateMutation: (period) => venturePeriodApiModule.updateMutation(ventureId, period),
      deleteMutation: (period) => venturePeriodApiModule.deleteMutation(ventureId, period),
      listSelector: () => data,
    },
    dialogProps: {
      fullWidth: true,
      maxWidth: 'xl',
    },
  };
  const props = useCrudDataTable(options);
  const initialValues = useMemo(() => ({
    sortBy: [{ id: 'period', desc: true }],
  }), []);

  return (
    <CrudDataTable
      {...props}
      data={data}
      initialValues={initialValues}
    />
  );
};

VenturePeriodsTable.propTypes = {
  ventureId: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
  })).isRequired,
};

export default VenturePeriodsTable;
