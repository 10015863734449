import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid, MenuItem, TextField,
} from '@material-ui/core';
import FormField from '../../../components/FormField';
import { GOAL_STATUS } from './GoalsFieldArray.config';

const options = [
  {
    value: GOAL_STATUS.COMPLETED,
    label: 'Completed',
  },
  {
    value: GOAL_STATUS.IN_PROGRESS,
    label: 'In Progress',
  },
  {
    value: GOAL_STATUS.INCOMPLETE,
    label: 'Incomplete',
  },
];

const GoalStatusSelect = (props) => (
  <TextField
    {...props}
    variant="outlined"
    label="Status"
    select
  >
    {options.map((option) => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ))}
  </TextField>
);

const GoalFieldSet = ({ name, label }) => (
  <Grid container spacing={2} alignItems="center">
    <Grid item>
      <FormField
        name={`${name}.status`}
        variant="outlined"
        label="Status"
        as={GoalStatusSelect}
      />
    </Grid>
    <Grid item xs>
      <FormField
        name={`${name}.text`}
        id={`${name}`}
        label={label}
        variant="outlined"
        autoComplete="off"
        fullWidth
      />
    </Grid>
  </Grid>
);

GoalFieldSet.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default GoalFieldSet;
