import React from 'react';

import { object, string } from 'yup';
import Form from '../../components/Form';
import FormField from '../../components/FormField';
import { ERROR_MESSAGES } from '../../constants/validation.constants';
import { DELETE_CELL_COLUMN, EDIT_CELL_COLUMN } from '../../components/CrudDataTable/CrudDataTable';
import ROLE from '../../constants/role.constants';

export const VALIDATION_SCHEMA = object({
  name: string().required(ERROR_MESSAGES.required),
});
export const INITIAL_VALUES = {
  name: '',
};
export const AUDIT_COLUMNS = [
  {
    Header: 'Last Updated At',
    id: 'updatedAt',
    accessor: (row) => new Date(row.updatedAt).toLocaleString(),
  },
  {
    Header: 'Last Updated By',
    id: 'updatedBy',
    accessor: (row) => row.updatedBy && row.updatedBy.email,
    permission: [ROLE.ADMIN],
  },
];
export const BASE_COLUMNS = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  ...AUDIT_COLUMNS,
  EDIT_CELL_COLUMN,
  DELETE_CELL_COLUMN,
];

export const ListForm = (props) => (
  <Form
    initialValues={INITIAL_VALUES}
    validationSchema={VALIDATION_SCHEMA}
    {...props}
  >
    <FormField
      name="name"
      label="Name"
      variant="outlined"
      fullWidth
    />
  </Form>
);
