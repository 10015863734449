import React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Provider as ReduxQueryProvider } from 'redux-query-react';
import { CssBaseline } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import theme from './theme';
import configureStore, { queriesSelector } from './modules/configureStore';
import App from './App';

const store = configureStore();

function Root() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Provider store={store}>
          <ReduxQueryProvider queriesSelector={queriesSelector}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </ReduxQueryProvider>
        </Provider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}

export default Root;
