import {
  object, string, number, array, date,
} from 'yup';
import { ERROR_MESSAGES } from '../../constants/validation.constants';
import { VALIDATION_SCHEMA as HEADCOUNT_VALIDATION_SCHEMA, HEADCOUNT_INITIAL_VALUE } from './HeadcountFieldSet';
import { SOURCE_VALIDATION_SCHEMA } from './SourceFieldArray/SourceFieldArray.config';
import { GOAL_VALIDATION_SCHEMA } from './GoalsFieldArray/GoalsFieldArray.config';

export const VALIDATION_SCHEMA = object({
  date: date().nullable().required(ERROR_MESSAGES.required),
  stage: object({
    id: string().required(ERROR_MESSAGES.required),
  }).nullable(),

  headcount: HEADCOUNT_VALIDATION_SCHEMA,

  progress: string(ERROR_MESSAGES.invalid),
  plans: string(ERROR_MESSAGES.invalid),
  risks: string(ERROR_MESSAGES.invalid),

  burnRate: number().required(ERROR_MESSAGES.required),
  sales: number().required(ERROR_MESSAGES.required),
  cashInBank: number(ERROR_MESSAGES.invalid)
    .min(0, ERROR_MESSAGES.minNumber)
    .required(ERROR_MESSAGES.required),

  payingCustomers: number().required(ERROR_MESSAGES.required),
  customerTrials: number().required(ERROR_MESSAGES.required),
  marketingSpend: number().required(ERROR_MESSAGES.required),
  technicalSpend: number().required(ERROR_MESSAGES.required),
  partners: string(),
  runway: number().integer(ERROR_MESSAGES.integer).required(ERROR_MESSAGES.required),

  ipStatus: string(),
  businessPlan: string().url(ERROR_MESSAGES.link),
  pitchDeck: string().url(ERROR_MESSAGES.link),

  goals: array().of(GOAL_VALIDATION_SCHEMA),
  debts: array().of(SOURCE_VALIDATION_SCHEMA),
  investments: array().of(SOURCE_VALIDATION_SCHEMA),
  grants: array().of(SOURCE_VALIDATION_SCHEMA),
  valuations: array().of(SOURCE_VALIDATION_SCHEMA),
});

export const INITIAL_VALUES = {
  date: null,
  stage: {
    id: '',
  },

  headcount: HEADCOUNT_INITIAL_VALUE,

  progress: '',
  plans: '',
  risks: '',

  burnRate: '',
  sales: '',
  cashInBank: '',

  payingCustomers: '',
  customerTrials: '',
  marketingSpend: '',
  technicalSpend: '',
  partners: '',
  runway: '',

  ipStatus: '',
  businessPlan: '',
  pitchDeck: '',

  investments: [],
  goals: [],
  debts: [],
  grants: [],
  valuations: [],
};
