import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Drawer,
  List,
} from '@material-ui/core';
import ListItemLink from '../components/ListItemLink';

const drawerWidth = '240px';
const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  drawer: {
    flexShrink: 0,
    whiteSpace: 'nowrap',
    '@media print': {
      display: 'none',
    },
  },
  drawerOpen: {
    width: drawerWidth,
    overflow: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: '64px',
  },
}));

const Sidebar = ({ open, menuItems, ...props }) => {
  const classes = useStyles();

  return (
    <Drawer
      position="fixed"
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
      open={open}
      {...props}
    >
      <div className={classes.toolbar} />
      <List>
        {menuItems.map((item) => item.title && (
          <ListItemLink
            key={item.path}
            button
            to={item.path}
            icon={<item.icon />}
            primary={item.title}
          />
        ))}
      </List>
    </Drawer>
  );
};

Sidebar.propTypes = {
  open: PropTypes.bool.isRequired,
  menuItems: PropTypes.arrayOf(PropTypes.shape({
    path: PropTypes.string.isRequired,
    icon: PropTypes.elementType,
    title: PropTypes.node,
  })).isRequired,
};

export default Sidebar;
