import React from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import { Container, Box } from '@material-ui/core';
import ResetPasswordFormContainer from '../blocks/Login/ResetPasswordForm';

const CreatePasswordPage = ({ history, location }) => (
  <Container component="main" maxWidth="xs">
    <Box mt={8}>
      <ResetPasswordFormContainer
        hash={qs.parse(location.search, { ignoreQueryPrefix: true }).hash}
        title="Create Password"
        onSuccess={() => history.push('/login')}
      />
    </Box>
  </Container>
);

CreatePasswordPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
};

export default CreatePasswordPage;
