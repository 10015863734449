import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

import FormatListBulletedOutlinedIcon from '@material-ui/icons/FormatListBulletedOutlined';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import TuneOutlinedIcon from '@material-ui/icons/TuneOutlined';
import GroupAddOutlinedIcon from '@material-ui/icons/GroupAddOutlined';

import { getFirstAvailableRoute } from './utils/renderRoutes';

import MainLayout from './layouts/MainLayout';
import VenturesPage from './pages/VenturesPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import CreatePasswordPage from './pages/CreatePasswordPage';
import ChangePasswordPage from './pages/ChangePasswordPage';
import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import HomeLayout from './layouts/HomeLayout';
import SettingsPage from './pages/SettingsPage';
import UserManagementPage from './pages/UserManagementPage';
import ROLE from './constants/role.constants';

const DashboardPage = lazy(() => import('./pages/DashboardPage'));
const VentureDetailsPage = lazy(() => import('./pages/VentureDetailsPage'));
const DataManagementPage = lazy(() => import('./pages/DataManagementPage'));


const RedirectToRelativePage = ({ route, routes }) => {
  const firstAvailableRoute = getFirstAvailableRoute(routes, route);

  return (
    <Redirect to={firstAvailableRoute.path} />
  );
};

export default [
  {
    path: '/reset-password',
    exact: true,
    component: ResetPasswordPage,
  },
  {
    path: '/create-password',
    exact: true,
    component: CreatePasswordPage,
  },
  {
    component: MainLayout,
    permission: {
      auth: true,
    },
    routes: [
      {
        path: '/dashboard',
        exact: true,
        title: 'Dashboard',
        icon: DashboardOutlinedIcon,
        component: DashboardPage,
        permission: [ROLE.ADMIN, ROLE.VIEWER],
      },
      {
        path: '/ventures',
        title: 'Ventures',
        exact: true,
        icon: FormatListBulletedOutlinedIcon,
        component: VenturesPage,
      },
      {
        path: '/ventures/:ventureId',
        exact: true,
        component: VentureDetailsPage,
      },
      {
        path: '/data-management',
        exact: true,
        title: 'Data Management',
        icon: SettingsOutlinedIcon,
        component: DataManagementPage,
        permission: [ROLE.ADMIN],
      },
      {
        path: '/user-management',
        exact: true,
        title: 'User Management',
        icon: GroupAddOutlinedIcon,
        component: UserManagementPage,
        permission: [ROLE.ADMIN],
      },
      {
        path: '/settings',
        exact: true,
        title: 'Settings',
        icon: TuneOutlinedIcon,
        component: SettingsPage,
        permission: [ROLE.ADMIN],
      },
      {
        path: '/change-password',
        exact: true,
        component: ChangePasswordPage,
      },
      {
        path: '/landing',
        exact: true,
        component: HomePage,
      },
      {
        path: '*',
        component: RedirectToRelativePage,
      },
    ],
  },
  {
    component: HomeLayout,
    routes: [
      {
        path: '/landing',
        exact: true,
        component: HomePage,
      },
      {
        path: '/login',
        exact: true,
        component: LoginPage,
      },
      {
        path: '*',
        component: RedirectToRelativePage,
      },
    ]
  },
];
