import React from 'react';
import PropTypes from 'prop-types';
import { ListItemIcon, ListItemText, ListItem } from '@material-ui/core';
import Link from './Link';

function ListItemLink({
  icon, primary, ...props
}) {
  return (
    <li>
      <ListItem {...props} component={Link}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

ListItemLink.propTypes = {
  icon: PropTypes.element,
  primary: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};
ListItemLink.defaultProps = {
  icon: null,
};

export default ListItemLink;
