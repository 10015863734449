import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from '@material-ui/pickers';

const DatePickerField = ({ field, form, ...other }) => {
  const { name, value } = field;
  const { setFieldValue, setFieldError, errors } = form;
  const currentError = errors[name];

  const handleChange = useCallback((date) => setFieldValue(name, date), [setFieldValue, name]);
  const handleError = useCallback((error) => {
    if (error && error !== currentError) {
      setFieldError(name, error);
    }
  }, [currentError, setFieldError, name]);

  return (
    <DatePicker
      autoOk
      name={name}
      value={value}
      helperText={currentError}
      error={Boolean(currentError)}
      onError={handleError}
      onChange={handleChange}
      {...other}
    />
  );
};

DatePickerField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.instanceOf(Date),
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
    setFieldError: PropTypes.func.isRequired,
    errors: PropTypes.shape({}),
  }).isRequired,
};

export default DatePickerField;
