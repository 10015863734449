import React from 'react';
import PropTypes from 'prop-types';
import {
  AppBar, Toolbar, Typography, Button,
} from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { renderRoutes } from '../utils/renderRoutes';
import Link from '../components/Link';

const HomeLayout = ({ route }) => (
  <>
    <AppBar position="static">
      <Toolbar style={{ justifyContent: 'space-between' }}>
        <Typography variant="h6">
          <Link to="/">Venture Dashboard</Link>
        </Typography>
        <Button color="inherit" component={NavLink} to="/login">Login</Button>
      </Toolbar>
    </AppBar>
    <div>
      {renderRoutes(route.routes)}
    </div>
  </>
);

HomeLayout.propTypes = {
  route: PropTypes.shape({
    routes: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default HomeLayout;
