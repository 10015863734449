import React from 'react';
import PropTypes from 'prop-types';
import CrudDataTable from '../../components/CrudDataTable/CrudDataTable';
import useCrudDataTable from '../../components/CrudDataTable/useCrudDataTable';

import {
  investmentsApiModule,
  debtsApiModule,
  ventureStagesApiModule,
  valuationsApiModule,
  grantsApiModule,
  cohortsApiModule,
  usersApiModule,
} from '../../modules/data.module';
import { venturesApiModule } from '../../modules/ventures.module';
import {
  ListForm,
  INITIAL_VALUES,
  BASE_COLUMNS,
} from './ListCrudTable.config';
import VentureForm, { INITIAL_VALUES as VANTURE_INITIAL_VALUES } from '../VentureForm/VentureForm';
import { VENTURE_TABLE_COLUMNS } from '../VentureTableList/VentureTableList';
import { userColumns } from '../UserManagement/UserManagement.config';
import UserForm, { INITIAL_VALUES as USER_INITIAL_VALUES } from '../UserManagement/UserForm';

export const TABLE_CONFIG_BY_TYPE = {
  ventures: {
    title: 'Venture',
    apiModule: venturesApiModule,
    FormComponent: VentureForm,
    initialValues: VANTURE_INITIAL_VALUES,
    columns: VENTURE_TABLE_COLUMNS,
  },
  investments: {
    title: 'Investment Source',
    apiModule: investmentsApiModule,
  },
  debts: {
    title: 'Debt Source',
    apiModule: debtsApiModule,
  },
  ventureStages: {
    title: 'Venture Stage',
    apiModule: ventureStagesApiModule,
  },
  valuations: {
    title: 'Valuation',
    apiModule: valuationsApiModule,
  },
  grants: {
    title: 'Grant Source',
    apiModule: grantsApiModule,
  },
  cohorts: {
    title: 'Cohort Source',
    apiModule: cohortsApiModule,
  },
  users: {
    title: 'User',
    apiModule: usersApiModule,
    columns: userColumns,
    FormComponent: UserForm,
    initialValues: USER_INITIAL_VALUES,
  },
};

const ListCrudTable = ({ type }) => {
  const options = {
    FormComponent: ListForm,
    initialValues: INITIAL_VALUES,
    columns: BASE_COLUMNS,
    ...TABLE_CONFIG_BY_TYPE[type],
  };
  const props = useCrudDataTable(options);

  return (
    <CrudDataTable
      {...props}
    />
  );
};

ListCrudTable.propTypes = {
  type: PropTypes.oneOf(Object.keys(TABLE_CONFIG_BY_TYPE)).isRequired,
};

export default ListCrudTable;
