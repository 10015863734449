import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

const Page = ({ children, ...props }) => (
  <Box p={3} {...props}>
    {children}
  </Box>
);

Page.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Page;
