import React from 'react';
import PropTypes from 'prop-types';
import { object, string } from 'yup';
import {
  Grid, Typography,
} from '@material-ui/core';

import { useMutation } from 'redux-query-react';
import Form from '../../components/Form';
import FormField from '../../components/FormField';
import { ERROR_MESSAGES } from '../../constants/validation.constants';
import { updateSettingsMutation } from '../../modules/app.module';
import useAppSettings from '../../hooks/useAppSettings';

export const VALIDATION_SCHEMA = object({
  feedbackLink: string()
    .url(ERROR_MESSAGES.link)
    .required(ERROR_MESSAGES.required),
});

export const INITIAL_VALUES = {
  feedbackLink: '',
};

const SettingsForm = () => {
  const [, updateAppSettings] = useMutation(updateSettingsMutation);
  const settings = useAppSettings();
  return (
    <Form
      initialValues={{
        ...INITIAL_VALUES,
        ...settings,
      }}
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={updateAppSettings}
      enableReinitialize
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4" align="center">
            Settings
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormField
            name="feedbackLink"
            id="feedback-link"
            label="Feedback Form Link"
            variant="outlined"
            fullWidth
          />
        </Grid>
      </Grid>
    </Form>
  );
};

SettingsForm.propTypes = {
  initialValues: PropTypes.shape({}),
};

SettingsForm.defaultProps = {
  initialValues: null,
};

export default SettingsForm;
