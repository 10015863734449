import { useRequest } from 'redux-query-react';
import { useSelector } from 'react-redux';
import { getSettingsRequets, settingsSelector } from '../modules/app.module';

const useAppSettings = () => {
  useRequest(getSettingsRequets());

  return useSelector(settingsSelector);
};

export default useAppSettings;
