import { createSelector } from 'reselect';
import { entitiesSelector } from './configureStore';
import crudModuleBuilder from './crudModuleBuilder';

export const venturesApiModule = {
  ...crudModuleBuilder({ subject: 'ventures', force: true }),
  cohortsSelector: createSelector(
    entitiesSelector,
    ({ ventures = [] }) => ventures.map(({ cohort }) => cohort)
      .filter((obj, pos, arr) => arr.map((mapObj) => mapObj.id).indexOf(obj.id) === pos),
  ),
};

export const getVentureByIdRequest = (ventureId) => ({
  url: `/api/ventures/${ventureId}`,
  force: true,
  update: {
    ventures: (prev = [], next) => {
      const index = prev.findIndex((item) => item.id === next.id);
      if (index >= 0) {
        prev.splice(index, 1, next);
        return [...prev];
      }
      return [next, ...prev];
    },
  },
  transform: (venture) => ({
    ventures: venture,
  }),
});

export const getVentureByIdSelector = (ventureId) => createSelector(
  entitiesSelector,
  ({ ventures = [] }) => ventures.find((item) => item.id === ventureId),
);

export const venturePeriodApiModule = {
  createMutation: (ventureId, body) => ({
    url: `/api/ventures/${ventureId}/periods`,
    force: true,
    body,
    options: {
      method: 'POST',
    },
    update: {
      ventures: (prev, next) => prev.map((item) => (item.id === next.id ? next : item)),
    },
    transform: (venture) => ({
      ventures: venture,
    }),
  }),
  updateMutation: (ventureId, body) => ({
    url: `/api/ventures/${ventureId}/periods/${body.id}`,
    body,
    options: {
      method: 'PUT',
    },
    update: {
      ventures: (prev, next) => prev.map((item) => (item.id === next.id ? next : item)),
    },
    transform: (venture) => ({
      ventures: venture,
    }),
  }),
  deleteMutation: (ventureId, periodId) => ({
    url: `/api/ventures/${ventureId}/periods/${periodId}`,
    options: {
      method: 'DELETE',
    },
    update: {
      ventures: (prev, next) => prev.map((item) => (item.id === next.id ? next : item)),
    },
    transform: (data) => ({
      ventures: data,
    }),
  }),
};
