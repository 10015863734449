import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import FormFieldList from '../../../components/FormFieldList';
import GoalFieldSet from './GoalFieldSet';
import { GOAL_INITIAL_VALUE } from './GoalsFieldArray.config';

const GoalsFieldArray = ({ name, push, remove }) => {
  const [{ value }] = useField(name);

  const handleAdd = useCallback(() => {
    push({
      key: Date.now(),
      ...GOAL_INITIAL_VALUE,
    });
  }, [push]);

  const handleRemove = useCallback((e, index) => {
    remove(index);
  }, [remove]);

  const renderItem = useCallback((item, index) => (
    <GoalFieldSet
      name={`${name}[${index}]`}
      label={`Goal ${index + 1}`}
    />
  ), [name]);

  return (
    <FormFieldList
      title="Goals"
      description="Please enter the goals that were set for this period"
      onAdd={handleAdd}
      onRemove={handleRemove}
      data={value}
      renderItem={renderItem}
    />
  );
};

GoalsFieldArray.propTypes = {
  name: PropTypes.string.isRequired,
  push: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
};

export default memo(GoalsFieldArray);
