import React, { Fragment, useCallback } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  TableFooter,
  TablePagination,
  TableSortLabel,
} from '@material-ui/core';
import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import {
  usePagination,
  useSortBy,
  useTable,
  useExpanded,
} from 'react-table';

const rowsPerPageOptions = [10, 20, 50];

const DataTable = ({ initialState, stickyHeader, ...props }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    flatColumns,
    page,
    gotoPage,
    setPageSize,
    prepareRow,
    renderRowSubComponent,
    state: { pageIndex, pageSize },
  } = useTable({
    initialState: {
      pageSize: 10,
      ...initialState,
    },
    ...props,
  }, useSortBy, useExpanded, usePagination);

  const items = page || rows;

  const handleChangePage = useCallback((e, newPage) => {
    gotoPage(newPage);
  }, [gotoPage]);

  const handleChangeRowsPerPage = useCallback((e) => {
    setPageSize(e.target.value);
  }, [setPageSize]);

  return (
    <Table stickyHeader={stickyHeader} {...getTableProps()}>
      <TableHead>
        {headerGroups.map((headerGroup) => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <TableCell
                {...(column.canSort
                  ? column.getHeaderProps(column.getSortByToggleProps())
                  : column.getHeaderProps())}
              >
                {column.render('Header')}
                {column.canSort && (
                <TableSortLabel
                  active={column.isSorted}
                  direction={column.isSortedDesc ? 'desc' : 'asc'}
                />
                )}
              </TableCell>
            ))}
            {renderRowSubComponent && (
            <TableCell />
            )}
          </TableRow>
        ))}
      </TableHead>
      <TableBody {...getTableBodyProps()}>
        {items.map((row) => {
          prepareRow(row);
          return (
            <Fragment {...row.getRowProps()}>
              <TableRow>
                {row.cells.map((cell) => (
                  <TableCell {...cell.column.props} {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </TableCell>
                ))}
                {renderRowSubComponent && (
                <TableCell padding="checkbox">
                  <IconButton size="small" {...row.getExpandedToggleProps()}>
                    {row.isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                </TableCell>
                )}
              </TableRow>
              {row.isExpanded ? (
                <TableRow>
                  <TableCell colSpan={flatColumns.length + 1} padding="none">
                    {renderRowSubComponent(row)}
                  </TableCell>
                </TableRow>
              ) : null}
            </Fragment>
          );
        })}
      </TableBody>
      {(rows.length > rowsPerPageOptions[0]) && (
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            labelRowsPerPage="Page Size"
            colSpan={flatColumns.length + 1}
            count={rows.length}
            rowsPerPage={pageSize}
            page={pageIndex}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </TableRow>
      </TableFooter>
      )}
    </Table>
  );
};

export default DataTable;
