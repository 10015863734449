import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid, InputAdornment, Card, CardContent, Typography,
} from '@material-ui/core';
import { FieldArray, Field } from 'formik';

import Form from '../../components/Form';
import FormField from '../../components/FormField';
import HeadcountFieldSet from './HeadcountFieldSet';
import GoalsFieldArray from './GoalsFieldArray';
import DataSelect from '../../components/DataSelect';
import { DATA_SOURCE } from '../../components/DataSelect/DataSelect.config';
import DatePickerField from '../../components/DatePickerField';
import SourceFieldArray from './SourceFieldArray';
import { INITIAL_VALUES, VALIDATION_SCHEMA } from './VenturePeriodForm.config';
import RunwayInput from './RunwayInput';

const VenturePeriodForm = (props) => (
  <Form
    initialValues={INITIAL_VALUES}
    validationSchema={VALIDATION_SCHEMA}
    {...props}
  >
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card variant="outlined">
          <CardContent>
            <Grid container item spacing={2}>
              <Grid item xs={6}>
                <Field
                  name="date"
                  views={['month']}
                  format="MMMM yyyy"
                  label="Period of Time"
                  disableFuture
                  inputVariant="outlined"
                  maxDate={new Date()}
                  component={DatePickerField}
                />
              </Grid>

              <Grid item xs={6}>
                <FormField
                  as={DataSelect}
                  source={DATA_SOURCE.VENTURE_STAGES}
                  name="stage.id"
                  label="Stage"
                  variant="outlined"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                <FormField
                  name="progress"
                  label="Progress"
                  variant="outlined"
                  multiline
                  rowsMax={4}
                  rows={4}
                  fullWidth
                  helperText="Please summarise the progress of the venture during this month – key milestones, major events etc."
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormField
                  name="plans"
                  label="Plans"
                  variant="outlined"
                  multiline
                  rowsMax={4}
                  rows={4}
                  fullWidth
                  helperText="Please summarise the plans for the coming month."
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormField
                  name="risks"
                  label="Risks"
                  variant="outlined"
                  multiline
                  rowsMax={4}
                  rows={4}
                  fullWidth
                  helperText="Please summarise the current major risks that the venture is subject to."
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={6} lg={4}>
        <FieldArray
          name="goals"
          component={GoalsFieldArray}
        />
      </Grid>

      <Grid item xs={12} md={6} lg={4}>
        <SourceFieldArray
          name="debts"
          sourceType={DATA_SOURCE.DEBTS}
        />
      </Grid>

      <Grid item xs={12} md={6} lg={4}>
        <SourceFieldArray
          name="investments"
          sourceType={DATA_SOURCE.INVESTMENTS}
        />
      </Grid>

      <Grid item xs={12} md={6} lg={4}>
        <SourceFieldArray
          name="grants"
          sourceType={DATA_SOURCE.GRANTS}
        />
      </Grid>

      <Grid item xs={12} md={6} lg={4}>
        <SourceFieldArray
          name="valuations"
          sourceType={DATA_SOURCE.VALUATIONS}
        />
      </Grid>

      <Grid item xs={12} md={6} lg={4}>
        <HeadcountFieldSet />
      </Grid>

      <Grid item xs={12} md={6} lg={4}>
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h6">Financials</Typography>
          </CardContent>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormField
                  name="burnRate"
                  label="Burn Rate"
                  variant="outlined"
                  type="number"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                  }}
                  fullWidth
                  helperText="Negative Cashflow this month."
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormField
                  name="sales"
                  label="Sales"
                  variant="outlined"
                  type="number"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                  }}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormField
                  name="cashInBank"
                  label="Cash in the Bank"
                  variant="outlined"
                  type="number"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <RunwayInput
                  name="runway"
                  label="Runway"
                  burnRateName="burnRate"
                  cashName="cashInBank"
                  fullWidth
                  helperText="This is a calculated amount."
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={6} lg={4}>
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h6">Markets</Typography>
          </CardContent>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormField
                  name="payingCustomers"
                  label="Paying Customers"
                  variant="outlined"
                  type="number"
                  fullWidth
                  helperText="Total number of paying customers this month."
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  name="customerTrials"
                  label="Customer Trials"
                  variant="outlined"
                  type="number"
                  fullWidth
                  helperText="Total number of customers in trials this month."
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormField
                  name="marketingSpend"
                  label="Marketing Spend"
                  variant="outlined"
                  type="number"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                  }}
                  fullWidth
                  helperText="Total amount spend on non-staff marketing."
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  name="technicalSpend"
                  label="Technical Spend"
                  variant="outlined"
                  type="number"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                  }}
                  fullWidth
                  helperText="Total amount spend on non-staff technical."
                />
              </Grid>

              <Grid item xs={12}>
                <FormField
                  name="partners"
                  label="Partners"
                  variant="outlined"
                  multiline
                  rowsMax={4}
                  rows={4}
                  fullWidth
                  helperText="Which partners have you been working with this month?"
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={6} lg={4}>
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h6">Other</Typography>
          </CardContent>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormField
                  name="ipStatus"
                  label="IP Status"
                  variant="outlined"
                  multiline
                  rowsMax={4}
                  rows={4}
                  fullWidth
                  helperText="Please describe the status of any IP within the venture."
                />
              </Grid>

              <Grid item xs={12}>
                <FormField
                  name="businessPlan"
                  label="Business Plan"
                  variant="outlined"
                  type="url"
                  fullWidth
                  helperText="Please provide a link to your current business plan."
                />
              </Grid>

              <Grid item xs={12}>
                <FormField
                  name="pitchDeck"
                  label="Pitch Deck"
                  variant="outlined"
                  type="url"
                  fullWidth
                  helperText="Please provide a link to your current pitch deck."
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

    </Grid>
  </Form>
);

VenturePeriodForm.propTypes = {
  initialValues: PropTypes.shape({
    stage: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
};

VenturePeriodForm.defaultProps = {
  initialValues: null,
};

export default VenturePeriodForm;
