import React from 'react';
import { Container } from '@material-ui/core';
import Page from './Page';
import SettingsForm from '../blocks/SettingsForm';

const SettingsPage = () => (
  <Page>
    <Container maxWidth="sm">
      <SettingsForm />
    </Container>
  </Page>
);

export default SettingsPage;
