import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';

export default {
  black,
  white,
  primary: {
    contrastText: white,
    dark: '#0059c1',
    main: '#4285f4',
    light: '#80b4ff',
  },
  // secondary: {
  //   contrastText: white,
  //   dark: '#3c3c3c',
  //   main: '#666666',
  //   light: '#949494',
  // },
  success: {
    contrastText: white,
    dark: '#007827',
    main: '#34A853',
    light: '#6bda81',
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400],
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400],
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400],
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600],
  },
  background: {
    default: '#EEEEEE',
    paper: white,
  },
  // icon: colors.blueGrey[600],
  // divider: colors.grey[200],
};
