import { createSelector } from 'reselect';
import { entitiesSelector } from './configureStore';

export const getSettingsRequets = (data) => ({
  url: '/api/settings',
  force: true,
  body: data,
  update: {
    settings: (prev, next) => next,
  },
  transform: (settings) => ({
    settings,
  }),
});

export const updateSettingsMutation = (data) => ({
  url: '/api/settings',
  body: data,
  update: {
    settings: (prev, next) => next,
  },
  transform: (settings) => ({
    settings,
  }),
});

export const settingsSelector = createSelector(
  entitiesSelector,
  ({ settings }) => settings,
);
