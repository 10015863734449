import React, { forwardRef, memo } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Link as UILink } from '@material-ui/core';

const Link = forwardRef(({ children, ...props }, ref) => (
  <UILink
    color="inherit"
    variant="inherit"
    underline="none"
    component={RouterLink}
    ref={ref}
    {...props}
  >
    {children}
  </UILink>
));

Link.propTypes = {
  children: PropTypes.node.isRequired,
};

export default memo(Link);
