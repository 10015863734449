import {
  investmentsApiModule,
  ventureStagesApiModule,
  valuationsApiModule,
  debtsApiModule,
  grantsApiModule,
  cohortsApiModule,
} from '../../modules/data.module';

export const DATA_SOURCE = {
  DEBTS: 'debts',
  VENTURE_STAGES: 'venture-stages',
  VALUATIONS: 'valuations',
  INVESTMENTS: 'investments',
  GRANTS: 'grants',
  COHORT: 'cohorts',
};

export const API_SOURCE_CONFIG = {
  [DATA_SOURCE.DEBTS]: debtsApiModule,
  [DATA_SOURCE.VENTURE_STAGES]: ventureStagesApiModule,
  [DATA_SOURCE.VALUATIONS]: valuationsApiModule,
  [DATA_SOURCE.INVESTMENTS]: investmentsApiModule,
  [DATA_SOURCE.GRANTS]: grantsApiModule,
  [DATA_SOURCE.COHORT]: cohortsApiModule,
};
