import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
} from '@material-ui/core';
import { string, object, ref } from 'yup';

import { useMutation } from 'redux-query-react';
import Form from '../../components/Form';
import FromField from '../../components/FormField';
import { ERROR_MESSAGES, VALIDATION_RULES } from '../../constants/validation.constants';
import { resetPasswordMutation } from '../../modules/user.module';

const INITIAL_VALUES = {
  hash: '',
  oldPassword: '',
  password: '',
  confirmPassword: '',
  serverError: '',
};

const VALIDATION_SCHEMA = object({
  password: VALIDATION_RULES.password
    .required(ERROR_MESSAGES.required),
  confirmPassword: string()
    .oneOf([ref('password'), null], 'Passwords must match')
    .required(ERROR_MESSAGES.required),
});

const PasswordForm = ({
  hash, oldPassword, title, ...props
}) => {
  const [, resetPassword] = useMutation(resetPasswordMutation);

  const initialValues = useMemo(() => ({
    ...INITIAL_VALUES,
    hash,
  }), [hash]);

  return (
    <Form
      initialValues={initialValues}
      validationSchema={VALIDATION_SCHEMA}
      onCancel={null}
      onSubmit={resetPassword}
      {...props}
    >
      <Typography variant="h4" align="center">
        {title}
      </Typography>

      {oldPassword && (
      <FromField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        type="password"
        id="old-password"
        label="Old Password"
        name="oldPassword"
        autoComplete="password"
      />
      )}

      <FromField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        type="password"
        id="password"
        label="New Password"
        name="password"
        autoComplete="password"
      />
      <FromField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="confirmPassword"
        label="Confirm Password"
        type="password"
        id="confirm-password"
        autoComplete="password"
      />
    </Form>
  );
};

PasswordForm.propTypes = {
  hash: PropTypes.string.isRequired,
  title: PropTypes.node.isRequired,
  oldPassword: PropTypes.bool,
};

PasswordForm.defaultProps = {
  oldPassword: false,
};

export default PasswordForm;
