import { string } from 'yup';

export const ERROR_MESSAGES = {
  required: 'Required.',
  positive: 'Positive.',
  invalid: 'Invalid value.',
  minLength: ({ min }) => `Minimum ${min} characters.`,
  maxLength: ({ max }) => `Maximum ${max} characters.`,
  minNumber: () => 'Invalid value.',
  maxNumber: () => 'Invalid value.',
  email: 'Invalid Email.',
  link: 'Invalid link.',
  integer: 'Invalid value.',
  password: 'Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character without any space.',
};

export const VALIDATION_RULES = {
  password: string()
    .trim()
    .min(8, ERROR_MESSAGES.password)
    .max(32, ERROR_MESSAGES.password)
    .matches(/[A-Z]/, ERROR_MESSAGES.password)
    .matches(/[a-z]/, ERROR_MESSAGES.password)
    .matches(/\d/, ERROR_MESSAGES.password)
    .matches(/[-=*!@#$%^&(){}[\]:;<>,.?/~+_|\\]/, ERROR_MESSAGES.password),
};
