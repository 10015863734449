import { AUDIT_COLUMNS } from '../DataManagement/ListCrudTable.config';
import { EDIT_CELL_COLUMN, DELETE_CELL_COLUMN } from '../../components/CrudDataTable/CrudDataTable';
import ROLE from '../../constants/role.constants';

export const ROLES = [ROLE.ADMIN, ROLE.VIEWER, ROLE.USER];

export const userColumns = [
  {
    Header: 'Email',
    id: 'email',
    accessor: 'email',
  },
  {
    Header: 'Role',
    id: 'role',
    accessor: 'role',
  },
  {
    Header: 'Activated',
    id: 'activated',
    accessor: ({ activated }) => (activated ? 'Yes' : 'No'),
  },
  ...AUDIT_COLUMNS,
  EDIT_CELL_COLUMN,
  DELETE_CELL_COLUMN,
];
