
import React from 'react';
import PropTypes from 'prop-types';
import { object, string } from 'yup';
import {
  Grid, MenuItem,
} from '@material-ui/core';

import Form from '../../components/Form';
import FormField from '../../components/FormField';
import { ERROR_MESSAGES } from '../../constants/validation.constants';
import ROLE from '../../constants/role.constants';

import { ROLES } from './UserManagement.config';

export const VALIDATION_SCHEMA = object({
  email: string()
    .email(ERROR_MESSAGES.email)
    .required(ERROR_MESSAGES.required),

  role: string().oneOf(ROLES),
});

export const INITIAL_VALUES = {
  email: '',
  role: ROLE.USER,
};

const UserForm = ({ initialValues, ...props }) => (
  <Form
    initialValues={{
      ...INITIAL_VALUES,
      ...initialValues,
    }}
    validationSchema={VALIDATION_SCHEMA}
    {...props}
  >
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormField
          name="email"
          id="email"
          label="Email"
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <FormField
          name="role"
          id="role"
          label="Role"
          variant="outlined"
          fullWidth
          select
        >
          {ROLES.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </FormField>
      </Grid>
    </Grid>
  </Form>
);

UserForm.propTypes = {
  initialValues: PropTypes.shape({}),
};

UserForm.defaultProps = {
  initialValues: null,
};

export default UserForm;
