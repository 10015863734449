import React from 'react';
import PropTypes from 'prop-types';

import { useMutation } from 'redux-query-react';
import { resetPasswordMutation } from '../../modules/user.module';
import PasswordForm from './PasswordForm';

const ResetPasswordForm = (props) => {
  const [, resetPassword] = useMutation(resetPasswordMutation);

  return (
    <PasswordForm
      onSubmit={resetPassword}
      title="Reset Password"
      {...props}
    />
  );
};

ResetPasswordForm.propTypes = {
  hash: PropTypes.string.isRequired,
};

export default ResetPasswordForm;
