import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Card,
  CardContent,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';

const getKey = (item, index) => item.id || item.key || index;

const FormFieldList = ({
  title, description, addLabel, renderItem, onAdd, onRemove, data,
}) => (
  <Card variant="outlined">
    <CardContent>
      <Typography gutterBottom variant="h6">
        {title}
      </Typography>
      {description && (
      <Typography variant="body2" color="textSecondary" component="p">
        {description}
      </Typography>
      )}
    </CardContent>
    <TableContainer>
      <Table>
        <TableBody>
          {data.map((item, index) => (
            <TableRow key={getKey(item, index)}>
              <TableCell align="center">
                {renderItem(item, index)}
              </TableCell>
              <TableCell align="center" padding="checkbox">
                <IconButton onClick={(e) => onRemove(e, index)}>
                  <CancelIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell colSpan={3}>
              <Button onClick={onAdd} variant="text" startIcon={<AddIcon />} color="primary">{addLabel}</Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  </Card>
);

FormFieldList.propTypes = {
  title: PropTypes.node.isRequired,
  description: PropTypes.node,
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
  })),
  addLabel: PropTypes.string,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  renderItem: PropTypes.func.isRequired,
};

FormFieldList.defaultProps = {
  addLabel: 'Add',
  data: [],
  description: null,
};

export default memo(FormFieldList);
