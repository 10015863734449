import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import Link from '../../components/Link';
import VenturePeriodsTable from './VenturePeriodsTable';
import CrudDataTable, { EDIT_CELL_COLUMN, DELETE_CELL_COLUMN } from '../../components/CrudDataTable/CrudDataTable';
import useCrudDataTable from '../../components/CrudDataTable/useCrudDataTable';
import { venturesApiModule } from '../../modules/ventures.module';
import VentureForm, { INITIAL_VALUES } from '../VentureForm/VentureForm';
import useAbility from '../../hooks/useAbility';
import { formatDateTime, formatMonth, sortByDate } from '../../helpers/dateUtils';
import { toCurrency } from '../../helpers/currency';
import ROLE from '../../constants/role.constants';

export const VENTURE_TABLE_COLUMNS = [
  {
    Header: 'Name',
    id: 'name',
    accessor: (row) => (
      <Link color="primary" component={Link} to={`/ventures/${row.id}`}>
        {row.name}
      </Link>
    ),
  },
  {
    Header: 'Cohort',
    id: 'cohort',
    accessor: (row) => row.cohort && (
      <Link color="primary" to={`/dashboard?cohorts[0]=${row.cohort.id}`}>
        {row.cohort.name}
      </Link>
    ),
  },
  {
    Header: 'Stage',
    id: 'stage',
    accessor: (row) => (row.periods.length ? row.periods[row.periods.length - 1].stage.name : '-'),
  },
  {
    Header: 'Periods',
    id: 'periods',
    accessor: (row) => row.periods[0] ? `${formatMonth(row.periods[0].date)} - ${formatMonth(row.periods[row.periods.length - 1].date)}` : '-',
    sortType: ({ original: { periods: a } }, { original: { periods: b } }) => sortByDate(a[0]?.date, b[0]?.date),
  },
  {
    Header: 'Total-Sales',
    id: 'total-sales',
    accessor: (row) => toCurrency(row.periods.reduce((total, { sales }) => total + sales, 0)),
  },
  {
    Header: 'Owner',
    id: 'owner',
    accessor: (row) => row.owner && row.owner.email,
    permission: [ROLE.ADMIN, ROLE.VIEWER],
  },
  {
    Header: 'Last Updated At',
    id: 'updatedAt',
    accessor: (row) => formatDateTime(row.updatedAt),
  },
  {
    ...EDIT_CELL_COLUMN,
    permission: [ROLE.ADMIN],
  },
  DELETE_CELL_COLUMN,
];

const renderRowSubComponent = ({ original: venture }) => (
  <Box px={2} pb={4}>
    <VenturePeriodsTable
      size="small"
      ventureId={venture.id}
      data={venture.periods}
    />
  </Box>
);

const VentureTableList = () => {
  const { can } = useAbility();
  const defaultExpaned = useMemo(() => ({
    0: !can([ROLE.ADMIN, ROLE.VIEWER]),
  }), [can]);

  const options = {
    title: 'Venture',
    FormComponent: VentureForm,
    initialValues: INITIAL_VALUES,
    columns: VENTURE_TABLE_COLUMNS,
    canCreate: can([ROLE.ADMIN]),
    apiModule: venturesApiModule,
    initialState: {
      expanded: defaultExpaned,
    },
  };
  const props = useCrudDataTable(options);

  return (
    <CrudDataTable
      {...props}
      title="Venture"
      renderRowSubComponent={renderRowSubComponent}
    />
  );
};

VentureTableList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })),
};

VentureTableList.defaultProps = {
  data: [],
};

export default VentureTableList;
